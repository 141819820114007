.wrapper {
  margin-top: 100px;
}
.header {
  text-align: center;
  margin-bottom: 40px;
}
.progress {
  margin: 0 auto;
  margin-top: 24px;
  width: 80%;
}