.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 40px;
}
.card {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 0;
  transition: all 0.2s ease-in-out;
  height: 100%;
  gap: 32px;
}
.card:hover {
  box-shadow: 0px 4px 20px rgba(200, 223, 255, 0.8);
  cursor: pointer;
}
.label {
  color: #2d2d2d;
  font-weight: 500;
  font-size: 20px;
}
