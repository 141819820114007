.container {
  background: #f6f8ff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(90, 91, 106, 0.12);
  border-radius: 12px;
  padding: 32px;
  transition: all 0.2s ease-in-out;
  height: 100%;
}
.header {
  padding-bottom: 16px;
  border-bottom: 1px solid #d7d7d7;
  height: 150px;
  display: grid;
  grid-auto-rows: max-content 1fr max-content;
  justify-items: flex-start;
}
.title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #2d2d2d;
  margin-bottom: 8px;
}
.description {
  color: #2d2d2d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;
}
.list {
  margin: 0;
  padding: 0;
  margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
  color: #2d2d2d;
}
.listItem {
  line-height: 160%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.icon {
  margin-top: 4px;
}
