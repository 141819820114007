.header {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 16px 0px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  padding: 0 10px;
  margin: 0 20px;
  color: #2D2D2D;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}
.link:hover {
  color: #4C88DA;
}