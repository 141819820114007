.header {
  background: #f7f9ff;
  text-align: center;
  color: #2d2d2d;
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 40px;
}
.heading {
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
}
.heading span {
  color: #4c88da;
}
.subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
.content {
}
.productHeader {
  background: #4c88da;
  border: 0.5px solid #ffffff;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.productContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.productContent:nth-child(2n+1) {
  background: #F7F9FF;
}
.productItem {
  padding: 8px 32px;
}
.divider {
  border-right: 0.5px solid #ffffff;
}
