.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  margin-top: 100px;
}
.address {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #2d2d2d;
  margin-top: 16px;
  margin-left: 8px;
}
.menus {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.menuHeading {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 24px;
  color: #2D2D2D;
}
.list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -13px;
}
.copyright {
  text-align: center;
  padding: 16px;
  border-top: 1px solid #f9f9f9;
  margin-top: 32px;
}
